import styled from 'styled-components';

const SectionWrapper = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  max-width: 1400px;
  width: 100%;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (max-width: 500px) {
    padding: 1em;
  }
`;

const SectionWrapperWhite = styled(SectionWrapper)`
  background-color: white;
`;

const SectionWrapperGrey = styled(SectionWrapper)`
  background-color: #f5f7f9;
`;

const FilterCloseButton = styled.div`
  position: absolute;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  text-align: right;
  right: 18px;
  bottom: 12px;
  padding: 10px 17px;
  color: #1e53bb;
  cursor: pointer;
`;

export { SectionWrapper, SectionWrapperWhite, SectionWrapperGrey, ContentWrapper, FilterCloseButton };
