import React, { useState } from 'react';
import styled from 'styled-components';
import { SectionWrapperWhite, ContentWrapper } from '~/styles/common';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import ModalVideo from 'react-modal-video';

const RentingIsEasy = () => {
  const [isVideoOpen, setVideoOpen] = useState(false);

  const rentingData = [
    {
      id: 1,
      heading: t`rentingIsEasy.point1.heading`,
      description: t`rentingIsEasy.point1.description`,
    },
    {
      id: 2,
      heading: t`rentingIsEasy.point2.heading`,
      description: t`rentingIsEasy.point2.description`,
    },
    {
      id: 3,
      heading: t`rentingIsEasy.point3.heading`,
      description: t`rentingIsEasy.point3.description`,
    },
    {
      id: 4,
      heading: t`rentingIsEasy.point4.heading`,
      description: t`rentingIsEasy.point4.description`,
    },
  ];
  return (
    <SectionWrapperWhite>
      <ModalVideo
        channel="custom"
        url="https://cdn-production.boatsetter.com/assets/boatsetter/videos/BS_1080p-1543646630757161a2da25747c54bca3.mp4"
        isOpen={isVideoOpen}
        onClose={() => {
          setVideoOpen(false);
        }}
      />

      <ContentWrapper>
        <SectionHeading>
          <Trans id="rentingIsEasy.heading" />
        </SectionHeading>

        <PointsWrapper>
          {rentingData.map((point) => (
            <Point key={point.id}>
              <Number>{point.id}</Number>
              <Heading>
                <Trans id={point.heading} />
              </Heading>
              <Description>
                <Trans id={point.description} />
              </Description>
            </Point>
          ))}
        </PointsWrapper>
      </ContentWrapper>
    </SectionWrapperWhite>
  );
};

export default RentingIsEasy;

const PointsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em;
  margin-left: -1em;
`;

const SectionHeading = styled.h2`
  margin-bottom: 0.5em;
  font-size: 2em;
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  flex: 0 1 25%;
  min-width: 150px;

  @media screen and (max-width: 1100px) {
    flex: 0 1 50%;
  }
`;

const Number = styled.div`
  border: solid 2px #000;
  color: #000;
  line-height: 2.8em;
  width: 3em;
  height: 3em;
  border-radius: 49.5%;
  display: inline-block;
  text-align: center;
  margin-bottom: 1em;
  font-weight: 600;
`;

const Heading = styled.div`
  color: #17233c;
  margin-bottom: 0.5em;
  font-size: 1.25em;
  line-height: 1.4;
  font-weight: 700;
`;

const Description = styled.div`
  font-size: 16px;
  color: #4b5765;
`;
